@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $colors) {
	$color-config: mat.m2-get-color-config($theme);
	$foreground: map.get($color-config, foreground);

	.sh-state-active {
		background-color: map.get($colors, 'muted');
		color: if(map.get($color-config, is-dark), black, white);
	}
}

@mixin theme($theme, $colors) {
	$color-config: mat.m2-get-color-config($theme);
	@if $color-config != null {
		@include color($theme, $colors);
	}
}
