@use 'sass:map';
@use '@angular/material' as mat;

@mixin override-theme($theme) {
	$config: mat.m2-get-color-config($theme);
	$accent: map.get($config, accent);

	.mat-mdc-option.mat-mdc-option-active {
		background-color: mat.m2-get-color-from-palette($accent) !important;
		.mdc-list-item__primary-text {
			color: mat.m2-get-color-from-palette(mat.m2-get-color-from-palette($accent, contrast), 50) !important;
		}

		&.sh-menu-selection-transparent {
			background-color: mat.m2-get-color-from-palette($accent, 0.12) !important;
		}
	}
}

@mixin override-style() {
	.mat-pseudo-checkbox {
		display: none !important;
	}

	.mdc-menu-surface.mat-mdc-select-panel {
		padding: 0 !important;
	}

	.mat-mdc-select {
		font-size: 14px !important;
	}
}
